/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/award%2Fapimondia-awards.png",
    alt: "第44届国际养蜂大会金奖、铜奖，第41届国际养蜂大会银奖"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/award%2F4fb70f84fc68cd53f97b8262487cde4.jpg",
    alt: "第44届国际养蜂大会铜奖证书"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/award%2Fxuehui2.png",
    alt: "中国养蜂学会全国蜂业突出贡献奖"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/award%2Fxuehui1.png",
    alt: "中国养蜂学会国际影响力奖（金奖）"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
