import GATSBY_COMPILED_MDX from "E:/codeRepository/gitCode/sdfbee/src/posts/fame.mdx";
import {MDXProvider} from "@mdx-js/react";
import * as React from "react";
import {Layout} from "../components/layout";
import {graphql} from "gatsby";
import styled from "@emotion/styled";
import {Card, Media, Columns} from "../components/common";
const ArticleContent = (styled.div)`
  margin: 0 auto;
  padding: 1rem;
  width: 1000px;
  @media (max-width: 1000px) {
    width: 100vw;
  }
`;
const ArticleSpacer = (styled.div)`
  width: 90%;
  height: 1px;
  background: grey;
  margin: 1rem auto;
`;
const components = {
  h1: props => React.createElement("h1", Object.assign({
    className: "title is-1"
  }, props)),
  h2: props => React.createElement("h2", Object.assign({
    className: "title is-2"
  }, props)),
  h3: props => React.createElement("h3", Object.assign({
    className: "title is-3"
  }, props)),
  h4: props => React.createElement("h4", Object.assign({
    className: "title is-4"
  }, props)),
  h5: props => React.createElement("h5", Object.assign({
    className: "title is-5"
  }, props)),
  h6: props => React.createElement("h6", Object.assign({
    className: "title is-6"
  }, props)),
  p: props => React.createElement("p", Object.assign({
    style: {
      textIndent: '2em',
      fontSize: '1rem',
      marginBottom: '1.5rem'
    }
  }, props)),
  img: props => React.createElement("figure", {
    className: "image",
    style: {
      maxWidth: '95vw'
    }
  }, React.createElement("img", props), props.alt ? React.createElement("p", {
    style: {
      textAlign: 'center',
      color: 'grey'
    }
  }, props.alt) : null),
  Card,
  Media,
  Columns
};
const PostPage = ({data, children}) => {
  console.log(data);
  return React.createElement(Layout, null, React.createElement(ArticleContent, null, React.createElement("h1", {
    className: "title",
    style: {
      fontSize: '3.5rem'
    }
  }, data.mdx.frontmatter.title), React.createElement("p", {
    className: "subtitle is-6",
    color: "grey"
  }, `${data.mdx.frontmatter.date.split('T')[0]} 来源：${data.mdx.frontmatter.source}`), React.createElement(ArticleSpacer), React.createElement(MDXProvider, {
    components: components
  }, children)));
};
PostPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
        source
      }
    }
  }
`;
export const Head = ({data}) => React.createElement("title", null, data.mdx.frontmatter.title, " - 神顶峰 雪蜜网");
